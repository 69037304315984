























































































import {Vue, Component, Prop} from 'vue-property-decorator';
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreateAnnualReportPayload from "@/dto/archive/CreateAnnualReportPayload";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {namespace} from "vuex-class";
import DocumentService from "@/services/DocumentService";
import { ifValid, processError } from "@/utils/ComponentUtils";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import { SublimeCompanyType } from "@/dto/SublimeCompanyType";
import { ServiceSet } from "@/dto/payment/ServiceSet";
import { PaymentMethod } from "@/constants/PaymentConstants";
import SelectOption from "@/components/common/SelectOption";
import PortalSelect from "@/components/common/PortalSelect.vue";

const AppModule = namespace("App");

@Component({
  computed: {
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    PaymentMethod() {
      return PaymentMethod;
    },
    SelectOption() {
      return SelectOption;
    },
  },
  components: {
    SingleFileHolder,
    AutoComplete,
    PortalInput,
    SimpleCompanySearch,
    PortalCheckbox,
    PortalDate,
    PortalSelect,
  }
})
export default class CreateAnnualReportModal extends Vue {

  @AppModule.Action private startLoading!: () => void;
  @AppModule.Action private stopLoading!: () => void;

  payload = new CreateAnnualReportPayload();

  @Prop()
  onSaved!: () => void

  created(): void {
    this.payload.invoice!.service = ServiceSet.COMPANY_ANNUAL_REPORT;
  }

  companyChanged(company: CompanyDTO){
    this.payload.companyId = company.id;
  }

  save(): void {
    ifValid(this,() => {
      if (this.payload.file && this.isInvoiceValid) {
        this.startLoading();
        if(!this.payload.paid) {
          this.payload.invoice = null;
        } else {
          this.payload.invoice!.sublimeCompany = SublimeCompanyType.SUBLIME_CONSULTING;
        }
        DocumentService.createAnnualReport(this.payload).then(
          ok => {
            this.stopLoading();
            this.onSaved();
            this.$modal.hideAll();
          },
          err => {
            this.stopLoading();
            processError(err, this);
          }
        )
      }
    })
  }

  get isInvoiceValid(): boolean {
    return !this.payload.paid || !!this.payload.invoice?.document;
  }

}
